import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Container className="text-center">
        <Row>
          <Col md={4}>
            Copyright © 2023 Tirumala Tirupati Devasthanams - All rights reserved{" "}
          </Col>
          <Col md={6}>
            <span>Call Center (24X7): 155 257 </span>
            <span id="fright"> |&emsp; Terms and Conditions &emsp;|&emsp; Guidelines & FAQs &emsp;|&emsp; Feedback</span>
          </Col>
          <Col md={2}>
            <a
              href="https://www.facebook.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={20} color="#1877f2" />
            </a>
            <a
              href="https://twitter.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={20} color="#1da1f2" />
            </a>
            <a
              href="https://www.instagram.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={20} color="#e4405f" />
            </a>
            <a
              href="https://www.youtube.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube size={20} color="#ff0000" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

// Import necessary dependencies
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";
import { IoMdLogIn } from "react-icons/io";

// Define your navigation component
const Navdata = () => {
  const [showNav, setShowNav] = useState(false);

  const handleToggleNav = () => {
    setShowNav(!showNav);
  };

  const handleCloseNav = () => {
    setShowNav(false);
  };

  return (
    <nav>
      <div className="menu-icon" onClick={handleToggleNav}>
        <div className={`line ${showNav ? "line-1" : ""}`}></div>
        <div className={`line ${showNav ? "line-2" : ""}`}></div>
        <div className={`line ${showNav ? "line-3" : ""}`}></div>
      </div>

      <div className={`nav-items  ${showNav ? "active" : ""}`}>
        <div className="close-icon" onClick={handleCloseNav}>
          &times;
        </div>
        <div className="all-nav">
          <div className="left-nav">
            <Link to="/">Home</Link>
            <Link to="/about">About TTD</Link>
            <Link to="/services">Online Services</Link>
            <Link to="/ebook">eBooks</Link>
            <Link to="/news">News</Link>
            <Link to="/seva">Srivari Seva</Link>
            <Link to="/svbc">SVBC</Link>
          </div>
          <div className="right-nav">
            <Link to="/login">
              Login&emsp;
              <IoMdLogIn />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navdata;



import React from 'react';
import PropTypes from 'prop-types';

function ExampleCarouselImage({ text }) {
  // Assuming you have images in a 'images' folder
//   const imagePath = `/images/${text.toLowerCase()}.jpg`;
const imagePath = `/images/${text.toLowerCase().replace(/\s/g, '_')}.png`;

  console.log(imagePath)

  return (
    <img
      className="d-block w-100"
      src={imagePath}
      alt={text}
      style={{ height: '330px', objectFit: 'cover', borderRadius:'30px', border:'5px solid gray' }}
    />
  );
}

ExampleCarouselImage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ExampleCarouselImage;

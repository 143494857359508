import React from 'react'
import "./Note.css";

const Note = () => {
  return (
    <div>
      <h2>Note</h2>
      <ol>
        <li>
          You can book for seva well in advance through seva. please read{" "}
          <a href='#'>instruction </a>before proceeding with the booking.
        </li>
        <li>
          Selection of seva does not imply confirmation only after entering
          pilgrim details and clicking on "pay now" button the selected sevva
          tickets will be blocked subject to availavility at that instance
        </li>
        <li>
          Pilgrim participating i srinivasa divyaanugarah homam are entitled to
          utiliize srivari special entry darsan (Rs 300/-) via the special entry
          darshan complex tirumala on the day of seva at 03:00pm{" "}
        </li>
        <li>
          To avail srivari special entry darshan plimgrim must participate in
          srinivasa divyaanugraha homam and get validate your seva tickets
          without fail
        </li>
      </ol>
    </div>
  )
}

export default Note

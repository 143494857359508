import React from 'react'
import "./Card.css";

const Card = (props) => {
  return (
    <div className='main-card'>
      <div className="cards">
        <div className="card">
          <img
            src={props.imgscr}
            alt="icons"
            className="card_img"
          />
          <div className="card_info">
            <span className="card_category">{props.title}</span>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Card

import React from "react";
import "./slider.css";
import Carousel from "react-bootstrap/Carousel";

import ExampleCarouselImage from './ExampleCarouselImage';
import Notice from "./notice";

function SliderItems() {
  return (
    <div className="custom-slider-container">
      <div className="custom-slider">
      <div className="slide-section">
            <Carousel slide={false}>
              <Carousel.Item>
                <ExampleCarouselImage text="First slide" />
                <Carousel.Caption>
                  <h3> </h3>
                  <p>
                     {/* Nulla vitae elit libero, a pharetra augue mollis interdum. */}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <ExampleCarouselImage text="Second slide" />
                <Carousel.Caption>
                  <h3> </h3>
                  <p>
                     {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. */}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <ExampleCarouselImage text="Third slide" />
                <Carousel.Caption>
                  <h3> </h3>
                  <p>
                     {/* Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur. */}
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
      </div>
      <div className="custom-notice">
        <h5>NEW UPDATES</h5>
        <Notice />
      </div>
    </div>
  );
}

export default SliderItems;

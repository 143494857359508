import React from 'react'
import SliderItems from '../components/carousels/slider'
import Card from '../components/card/Card';
import Data from '../components/card/Data';
import { FaArrowRight } from "react-icons/fa";


const Home = () => {
  return (
    <div>
       <SliderItems/>
      <h3>Pilgrim services <FaArrowRight size={18} /></h3>
      <div className="card-data">
        
        {Data.map((item, index) => (
          <Card key={index} {...item} />
        ))}
      </div>
    </div>
  )
}

export default Home

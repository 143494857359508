

const Data = [
    {
      
        imgscr : './images/KALYANAVEDIKA_WEB_ICON.png',
        title : 'Srinivasa Divyaanugraha Homam',
        links : 'https://www.google.co.in'
    },

    {
    
        imgscr : './images/SED_WEB_ICON.png',
        title : 'Special Entry Darshan (Ammavari Temple)',
        links : 'https://www.google.co.in'
    },

    {
    
        imgscr : './images/ACC_WEB_1.png',
        title : 'Accomodation',
        links : 'https://www.google.co.in'
    },

    {
    
        imgscr : './images/SEVA_WEB_ICON.png',
        title : 'Srivari Arjitha sevas(virtual participation)',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/SED_WEB_ICON.png',
        title : 'special entry darshan',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/APD_WEB_ICON.png',
        title : 'Angapradakshinam',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/SEVA_WEB_ICON.png',
        title : 'Srivari Aritha Sevas, Tirumala',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/PLD_WEB_ICON.png',
        title : 'Differently Abled/Sr.Citizen Darsan',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/SEVA_DIP_WEB_ICON.png',
        title : 'Seva electronic DIP',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/SEVA_WEB_ICON.png',
        title : 'Donor/USSES Privileges',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/USSES_WEB_ICON.png',
        title : 'SV Pranadana Trust Donation',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/USSES_WEB_ICON.png',
        title : 'Swamyvari(e-hundi)',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/USSES_WEB_ICON.png',
        title : 'Ammavari(e-hundi)',
        links : 'https://www.google.co.in'
    },
    
    {
    
        imgscr : './images/KALYANAMANDAPAM_WEB_ICON.png',
        title : 'Lalyanamandapam',
        links : 'https://www.google.co.in'
    },
    {
    
        imgscr : './images/PANCHAGAVYA_WEB_ICON.png',
        title : 'Panchagavya Product',
        links : 'https://www.google.co.in'
    },


]

export default Data;
import React from "react";
import "./notice.css";

const Notice = () => {
  return (
    <div className="notice-container">
      <div className="notice-content">
        <div className="logo-data">
          <img
            src="./images/new_1.png" alt="new logo"
            style={{ height: "20px", width: "30px", marginTop: "5px" }}
          />
          <p>
            Worshipping the Gods of your choice should be done daily, without
            any break to the continuity. Also, the puja should be done twice a
            day, preferably, i.e., once in the morning and once in the evening.
          </p>
        </div>
        <div className="logo-data">
          <img
            src="./images/new_1.png" alt="new logo"
            style={{ height: "20px", width: "30px", marginTop: "5px" }}
          />
          <p>
            The Puja place at your home should have a Hindu gods statue of more
            than 15 inches. We should not have deities bigger than this.
          </p>
        </div>
        <div className="logo-data">
          <img
            src="./images/new_1.png" alt="new logo"
            style={{ height: "20px", width: "30px", marginTop: "5px" }}
          />
          <p>
            Do not place three idols of any same God or any Goddess in your puja
            sthal.
          </p>
        </div>
        <div className="logo-data">
          <img
            src="./images/new_1.png" alt="new logo"
            style={{ height: "20px", width: "30px", marginTop: "5px" }}
          />
          <p>
            The temple at home should not have any gifted, wooden, or fiber
            idols. Only the Hindu gods idol, which is being worshipped by you
            daily, should be placed.
          </p>
        </div>
        <div className="logo-data">
          <img
            src="./images/new_1.png" alt="new logo"
            style={{ height: "20px", width: "30px", marginTop: "5px" }}
          />
          <p>
            Placing God's clothes, accessories, books & other puja items on top
            of your mandir is not considered good.{" "}
          </p>
        </div>

        <div className="logo-data">
          <img
            src="./images/new_1.png" alt="new logo"
            style={{ height: "20px", width: "30px", marginTop: "5px" }}
          />{" "}
          <p>
            Hindu saints have mentioned that puja is complete when you offer
            your daily prayers to at least five Hindu Gods, also termed as
            Panchdev, of which one should be your Ishta Devta. The five
            essential Gods to be prayed are Lord Surya, Ganesha, Durga, Shiva,
            and Vishnu.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Notice;

import React from 'react';
import "./ActionButton.css";

const ActionButtons = () => {
  return (
    <section id="action-btn">
      <button className="transparent-button">Back</button>
      <button className="purple-button">Continue</button>
    </section>
  );
};

export default ActionButtons;

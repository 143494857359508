import React from 'react';

const ColoredCircleIcon = ({ fillColor, outlineColor }) => {
  return (
    <div style={{ color: outlineColor }}>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="8" fill={fillColor} stroke={outlineColor} strokeWidth="2" />
      </svg>
    </div>
  );
};

export default ColoredCircleIcon;

import React from "react";
import "./header.css";

const Header = () => {
  return (
    <div className="top-header">
      <div className="header-row">
        <div className="header-left">
          <img
            src="./images/TTD_Logo (1).png"
            alt="logo"
            className="header-logo"
          />
        </div>

        <div className="header-center">
          <img src="./images/murthy.png" alt="" className="murthy-logo" />
        </div>

        <div className="header-right">
          <div className="header-content">
            <span style={{ fontSize: "14px" }}>Namaskaram Pilgrim</span>

            <span style={{ fontSize: "14px", fontWeight: 600 }}>
              Om Namo Venkatesaya
            </span>
          </div>
          {/* <div className="header-content-img">
              <img src="https://ttdevasthanams.ap.gov.in/muteIcon.svg" alt="muteIcon" />
         
          </div> */}

          <img
            src="./images/TTD_MainBanner_Garuda_Morning.png"
            alt=""
            className="garuda"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import Note from "./Note";
import ActionButtons from "./ActionButton";

const PointToKnow = () => {
  return (
    <div style={{margin:'0  50px 50px', paddingTop:'30px'}}>
      <Note/>
      <ActionButtons />
    </div>
  );
};

export default PointToKnow;

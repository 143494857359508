import React from 'react'

const Seva = () => {
    const divStyle = {
        backgroundColor: '#f0f0f0', // Change to your desired background color
        padding: '20px',            // Adjust padding as needed
        textAlign: 'center',        // Adjust text alignment
        fontSize: '1.5em',           // Adjust font size
        height:'74vh',
        width:'auto',
      };
  return (
    <div style={divStyle}>
      Welcome in SriVariSeva
    </div>
  )
}

export default Seva

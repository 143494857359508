import "./ChooseDate.css";
import { useState } from "react";
import React from "react";
import Datepicker from "./Datepicker";
import { LuRefreshCcw } from "react-icons/lu";
import ColoredCircleIcon from "./ColorCircleIcon";
import TempleDropdown from "./Templeselect";
import SevaTemple from "./Sevatemple";
import PointToKnow from "./PointToKnow";

const generateMonths = () => {
  // Get the current date
  const currentDate = new Date();

  // Generate the next 6 months from the current date
  const monthsArray = Array.from({ length: 3 }, (_, index) => {
    const nextMonthDate = new Date(currentDate);
    nextMonthDate.setMonth(currentDate.getMonth() + index + 1);

    // Handle month and year increment manually
    const nextMonth = (currentDate.getMonth() + index + 1) % 12;
    nextMonthDate.setMonth(nextMonth);

    if (nextMonth < currentDate.getMonth() + index + 1) {
      nextMonthDate.setFullYear(currentDate.getFullYear() + 1);
    }

    return nextMonthDate;
  });

  return [currentDate, ...monthsArray];
};

const ChooseDate = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };
  const monthsArray = generateMonths();

  return (
    <>


      <section id="select-choice">
        <TempleDropdown />
        <SevaTemple />
      </section>

      <div className="calender-container">
        <section id="seva-header">
          <span>Seva slot</span>
          <span>
            <LuRefreshCcw />
            Refresh
          </span>
        </section>
        <section id="pick-date">
          {monthsArray.map((month, index) => (
            <Datepicker
              key={index}
              initialDate={month}
              selectedDate={selectedDate}
              onDateClick={handleDateClick}
            />
          ))}
        </section>
        <section id="color-type">
          <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
            {" "}
            <ColoredCircleIcon fillColor="#bff05d" outlineColor="#bff05d" />
            Available
          </span>   
          <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
            <ColoredCircleIcon fillColor="#b62222" outlineColor="#b62222" />
            Quota full
          </span>
          <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
            <ColoredCircleIcon fillColor="#1d81b1" outlineColor="#1d81b1" />
            Filling fast
          </span>
          <span style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
            <ColoredCircleIcon fillColor="#b9a7a7" outlineColor="#b9a7a7" />
            Quota not available
          </span>
        </section>
      </div>
      <section id="know-this">
        <PointToKnow />
      </section>
    </>
  );
};

export default ChooseDate;

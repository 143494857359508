import React, { useState } from 'react';
import Select from 'react-select';

const TempleDropdown = () => {
  // Options for the dropdown
  const templeOptions = [
    { value: 'temple1', label: 'Temple 1' },
    { value: 'temple2', label: 'Temple 2' },
    { value: 'temple3', label: 'Temple 3' },
    // Add more temples as needed
  ];

  // State to store selected temples
  const [selectedTemples, setSelectedTemples] = useState([]);

  // Handle change in selected temples
  const handleSelectChange = (selectedOptions) => {
    setSelectedTemples(selectedOptions);
  };

  // Custom styles to increase the width of the dropdown
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '500px', // Set the desired width
      backgroundColor: 'transparent', // Set background color to transparent
      border: 'none', // Remove default border
      borderBottom: '1px solid #bbb', // Add bottom border
      fontFamily: 'sans-serif', // Set the desired font family
      fontSize : '15px'
    }),
  };

  return (
    <div>
      <label style= {{fontFamily:'sans-serif', fontSize:'16px'}}>Select Temples:</label>
      <Select
        isMulti
        options={templeOptions}
        value={selectedTemples}
        onChange={handleSelectChange}
        styles={customStyles} // Apply custom styles
      />
    </div>
  );
};

export default TempleDropdown;

// Datepicker.js
import React from "react";
import dayjs from "dayjs";
import { generateDate, months } from "./calendar";
import cn from "./cn";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import "./Datepicker.css";

const Datepicker = ({ initialDate, selectedDate, onDateClick }) => {
  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const currentDate = dayjs();
  const [today, setToday] = React.useState(dayjs(initialDate));

  return (
    <div className="datepicker-container">
      <div className="datepicker-calendar">
        <div className="datepicker-header">
          <h1 className="select-none font-semibold">
            {months[today.month()]}, {today.year()}
          </h1>
          <div className="datepicker-navigation">
            <GrFormPrevious
              className="navigation-icon"
              onClick={() => {
                setToday(today.month(today.month() - 1));
              }}
            />
            <h1
              className="today-text"
              onClick={() => {
                setToday(currentDate);
                onDateClick(null); // Clear selected date
              }}
            >
              Today
            </h1>
            <GrFormNext
              className="navigation-icon"
              onClick={() => {
                setToday(today.month(today.month() + 1));
                onDateClick(null); // Clear selected date
              }}
            />
          </div>
        </div>
        <div className="datepicker-days-header">
          {days.map((day, index) => (
            <h1 key={index} className="day-header">
              {day}
            </h1>
          ))}
        </div>

        <div className="datepicker-days-grid">
          {generateDate(today.month(), today.year()).map(
            ({ date, currentMonth, today }, index) => (
              <div key={index} className="datepicker-day-cell">
                <h1
                  className={cn(
                    currentMonth ? "" : "non-current-month",
                    today ? "today-cell" : "",
                    selectedDate &&
                      selectedDate.isSame(date, "day") &&
                      "selected-cell",
                    "day-number"
                  )}
                  onClick={() => onDateClick(dayjs(date))}
                >
                  {date.date()}
                </h1>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Datepicker;

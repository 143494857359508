import React, { useState } from 'react';
import Select from 'react-select';

const SevaTemple = () => {
  // Options for the dropdown
  const SevaOption = [
    { value: 'All', label: 'All' },
    { value: 'Sri srinivasa Divyaanugraha Homam', label: 'Sri srinivasa Divyaanugraha Homam' },
    { value: 'Asst Prist worship', label: 'Asst Prist worship' },
    // Add more sevas as needed
  ];

  // State to store selected sevas
  const [selectedSeva, setSelectedSeva] = useState([]);

  // Handle change in selected sevas
  const handleSelectChange = (selectedOptions) => {
    setSelectedSeva(selectedOptions);
  };

  // Custom styles to increase the width of the dropdown
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '500px', // Set the desired width
      backgroundColor: 'transparent', // Set background color to transparent
      border: 'none', // Remove default border
      borderBottom: '1px solid #bbb', // Add bottom border
      fontFamily: 'sans-serif', // Set the desired font family
      fontSize : '15px'

    }),
  };

  return (
    <div>
      <label style={{fontFamily:'sans-serif', fontSize:'16px'}}>Select Seva:</label>
      <Select
        isMulti
        options={SevaOption}
        value={selectedSeva}
        onChange={handleSelectChange}
        styles={customStyles} // Apply custom styles
      />
    </div>
  );
};

export default SevaTemple;

import "./App.css";
import React from "react";

import Header from "./components/header/header";

import Footer from "./components/footer/footer";
import Home from "./pages/home";
import About from "./pages/about";
import Ebook from "./pages/ebook";
import News from "./pages/news";
import Services from "./pages/onlineservices";
import Seva from "./pages/srivariseva";
// import Svbc from "./pages/svbc";

// import Navitems from "./components/navbar/navbar";
import Navdata from "./components/Nav/NavBar";
import { Route, Routes } from "react-router-dom";
import ChooseDate from "./components/util/ChooseDate";

function App() {
  return (
    <div className="App">
      <Header />
     <Navdata />
     

      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/ebook" element={<Ebook/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/seva" element={<Seva/>} />
        <Route path="/svbc" element={<ChooseDate/>} />
       
      </Routes>

      <Footer /> 
    </div>
  );
}

export default App
import React from 'react'

const About = () => {
    const divStyle = {
        backgroundColor: '#f0f0f0', 
        padding: '20px',            
        textAlign: 'center',        
        fontSize: '1.5em',           
        height:'74vh',
        width:'auto',
      };
  return (
    <div style={divStyle}>
      Welcome you About Module
    </div>
  )
}

export default About
